import { areaList } from '@vant/area-data';
import {
    saveAddress,
    addressDetail
} from '@/utils/address.js';
export default {
    setup() {
        return { areaList };
    },
    data() {
        return {
            isDelete: false,
            isToast: false,
            addressId: '',
            addressEditInfo: {}
        }
    },
    created() {
        this.addressId = this.$route.query.addressId;
        if (this.addressId) {
            this.addressDetailFunc();
        }
    },
    methods: {
        // 获取地址详情
        addressDetailFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                addressId: this.addressId
            }
            addressDetail(data).then(res => {
                console.log('详情', res)
                if (res.code == 1) {
                    let aData = res.result;
                    let cityData = aData.city.split(' ');
                    this.addressEditInfo = {
                        name: aData.customerName,
                        tel: aData.phone,
                        province: cityData[0],
                        city: cityData[1],
                        county: cityData[2],
                        areaCode: aData.cityCode,
                        addressDetail: aData.area,
                        postalCode: aData.areaCode,
                        isDefault: aData.isDefault
                    }
                    this.$forceUpdate();
                }
            })
        },
        // 保存地址
        onSave(e) {
            console.log('e', e)
            let data = {
                memberId: this.$cookies.get('memberId'),
                defaultSelect: e.isDefault ? 1 : 0, // (1=默认地址，0=非默认地址)
                addressMobilePhone: e.tel,
                addressCustomerName: e.name,
                addressCity: e.province + ' ' + e.city + ' ' + e.county, //(省市区)
                addressArea: e.addressDetail, //详细地址
                areaCode: e.postalCode, // 邮政编码
                addressId: this.addressId ? this.addressId : '', // (如果传了则更新，如果不传则新增)
                cityCode: e.areaCode
            }
            console.log('data', data)
            saveAddress(data).then(res => {
                console.log('保存地址', res)
                if (res.code == 0) {
                    this.$toast({
                        forbidClick: true,
                        message: '地址保存成功',
                        type: 'success',
                        duration: 2000
                    })
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 2000);
                } else {
                    this.$toast({
                        forbidClick: true,
                        message: res.message,
                        type: 'error',
                        duration: 2000
                    })
                }
            })
        },
    },
}