<template>
  <div class="address">
    <div class="address-main">
      <van-address-edit
        :area-list="areaList"
        :address-info="addressEditInfo"
        :show-delete="isDelete"
        show-set-default
        area-placeholder="省 / 市 / 区"
        tel-maxlength="11"
        @save="onSave"
      />
    </div>
    <!-- 添加地址已满 -->
    <van-popup v-model:show="isToast" position="center" round>
      <div class="address-toast">
        <div class="flex flex-center align-center address-toast-title">
          <span></span>
          地址添加已满
          <span></span>
        </div>
        <div class="address-toast-msg">
          需要管理地址库后<br/>才可继续添加新地址
        </div>
        <div class="flex flex-between address-toast-btn">
          <van-button class="address-toast-btn-list" plain type="default" round>取消</van-button>
          <van-button class="address-toast-btn-list" type="default" round color="#E62129">管理地址</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss">
  .address .van-field__control {
      text-align: right;
  }
  .address .van-switch--on{
    background-color: #E62129;
  }
  .van-picker__cancel,.van-picker__confirm{
    color: #E62129 !important;
  }
  .van-field__error-message{
    text-align: right;
  }
  .address{
    .van-address-edit{
      padding-top: 15px !important;
    }
  }
</style>
<style lang="scss" scoped>
  @import "./index.scss";
</style>